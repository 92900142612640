import React, { useEffect, useRef, useState } from "react";
import {  Box, Button, Container, Grid, Popover, TextField, Typography} from "@mui/material";
import PageTitle from "../../common/PageTitle";
import { useTranslation } from "react-i18next";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import RoleManagementService from "../services/roleManagementService";
import AdminstratorSite from "./AdminstratorSite";
import SellerSite from "./SellerSite";
import ReceivingEmailPermission from "./EmailPermission";
import SellerPermission from "./SellerPermission";
import RoleManagementSystem from "..";
import SimpleReactValidator from "simple-react-validator";

const CreateUserRole = (props: any) => {

    const { showSnackbar, roleName, handleLoader, roleNameId } = props;

    const[t] = useTranslation();
    const [anchorElPopover, setAnchorElPopover] = useState(null);
    const [openRms,setOpenRms]=useState<boolean>(false);
    const [editRoleName,setEditRoleName]=useState<string>(roleName);
    const [sellerData, setSellerData] =useState<any>([]);
    const [adminData, setAdminData] =useState<any>([]);
    const [skeleton, setSkeleton] = useState(true);
    const [permissionData, setPermissionData] =useState<any>([]);
    const [payloadData,setPayloadData] = useState<any>({});
    const [roleData, setRoleData] =useState<any>([]);
    const [storeData, setStoreData] =useState<any>([]);
    const [accessToAllSeller,setAccessToAllSeller]=useState<any>();

    const handleInsPopoverOpen = (event:any) => {
        setAnchorElPopover(event.currentTarget);
    };
    
    const handleInsPopoverClose = () => {
        setAnchorElPopover(null);
    };

    useEffect(()=>{
        window.scrollTo(0, 0);
        sellerPermissionInfoApiCall();
    },[]);

    const [, forceUpdate] = useState(0);
    
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
                max: t("role_management_system.max_100_char"),
            },
        })
    );
    useEffect(() => {
        forceUpdate(1);
    });

    useEffect(()=>{ 
        if(Object.keys(roleData).length && editRoleName){
            setPayloadData((prev:any)=>{
                const payload = {...prev};
                payload.modules = roleData?.admin.concat(roleData?.seller);
                payload.roleName = editRoleName;
                delete payload.emailPermissions;
                delete payload.roleId;
                delete payload.userType;
                return payload;
            });
        }
    },[roleData, editRoleName]);
    
    /**
 *
 * @param roleNameId - requesting roleNameId for managing seller permission
 * @returns - returns API response for Seller Permissions table in manage user role
 * @description - function to request Seller Permission get API 
 */
    const sellerPermissionInfoApiCall = () => {  
        setSkeleton(true);
        // handleLoader(true);
        const payload = {};
        const isHitDummyURL = false;
        new RoleManagementService(payload, isHitDummyURL)
            .sellerPermissionList(roleNameId)
            .then((res:any) => {
                setSellerData(res?.modules?.seller);
                setAdminData(res?.modules?.admin);
                setPermissionData(res?.emailPermissions);
                setStoreData(res?.sellers);
                // setPayloadData(res);
                setAccessToAllSeller((res?.accessToAllSeller));
                // handleLoader(false);
                setSkeleton(false);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
            });  
    };

    const handleBackBtn = () => {
        window.scrollTo(0, 0);     
        setOpenRms(true);  
    };
    /**
 *
 
 * @description - function to handle Save Button functionality
 */
    const handleSaveBtn = () => {
        if(!(payloadData?.accessToAllSeller)){
            if(payloadData?.sellers?.length > 0){
                saveUserRolePermission();
            }else{
                showSnackbar(t("role_management_system.no_store_selected"), false);              
            }
        }else{
            saveUserRolePermission();
        }           
    };


    /**
 *
 * @payload - permissions edit by user to manage user role permisssions
 * @returns - returns API response for saving user role permissions
 * @description - function to request Save User Role Permission Post API 
 */
    const saveUserRolePermission = () => {
        if(validator.current.allValid()){
            handleLoader(true);
            const payload = payloadData;
            const isHitDummyURL = false;
            new RoleManagementService(payload, isHitDummyURL)
                .saveUserRoleDetail(roleNameId)
                .then((res) => {
                    showSnackbar(res.message, true);
                    handleLoader(false);
                    setOpenRms(true);
                })
                .catch((err) => {
                    showSnackbar(err?.message, false);
                    handleLoader(false);
                });
            window.scrollTo(0, 0);
             
        } else {
            validator.current.showMessages();
        } 
    };

    /**
 *
 * @description - function to handle role name change and set it to user role state
 */
    const handleRoleNameChange = (e: any) => {
        setEditRoleName(e.target.value);
        validator.current.showMessages();
    };

    return (
        <>
            { openRms ? 
                <RoleManagementSystem showSnackbar={showSnackbar} handleLoader={handleLoader}/> 
                : (
                    <>
                        <Popover
                            open={Boolean(anchorElPopover)}
                            anchorEl={anchorElPopover}
                            onClose={handleInsPopoverClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            className="instruction_popover"
                
                        >
                            <Box sx={{padding:2}}>
                                <Typography className="rms_rules_headlines"> {t("role_management_system.create_a_role_you_desire")}</Typography>
                                <Typography>{t("role_management_system.manage_role_instruction_2")}</Typography>
                                <Typography>{t("role_management_system.manage_role_instruction_3")}</Typography>
                                <Typography>{t("role_management_system.manage_role_instruction_4")}</Typography>
                                <Typography>{t("role_management_system.manage_role_instruction_5")}</Typography>
                                <Typography>{t("role_management_system.manage_role_instruction_6")}</Typography>
                                <Typography>{t("role_management_system.manage_role_instruction_7")}</Typography>
                                <Typography>{t("role_management_system.manage_role_instruction_8")}</Typography>        
                            </Box>
                        </Popover>
                        <Container>
                            <Grid container spacing={0} sx={{ py: 5 }}>
                                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ py: 2 }}>
                                    <PageTitle title={t("role_management_system.create_user_role")} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ py: 6 }}> 
                                    <Button
                                        className="float_right table_btn"
                                        variant="contained"
                                        onClick={() => handleBackBtn()}
                                    >
                                        {t("role_management_system.back")}
                                    </Button>                     
                                    <InfoRoundedIcon
                                        onClick={handleInsPopoverOpen}
                                        color="primary"
                                        sx={{mt:0}}
                                        className="rms_instruction_icon float_right"
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            <Grid container spacing={2}>
                                <Grid item   xs={12} sm={12} md={12} lg={8} xl={6}>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} md={6} lg={6} > 
                                            <TextField
                                                label={t("role_management_system.role_name")}
                                                onChange={handleRoleNameChange}
                                                value={editRoleName}                          
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                            /> 
                                            {validator.current.message("editRoleName", editRoleName , ["required",{ max: 100 }])}
 
                                        </Grid>  
                                        <Grid item xs={12} sm={6} md={6} lg={6} >   
                                        </Grid>                  
                                    </Grid>                     
                                    <AdminstratorSite showSnackbar={showSnackbar} adminstratorSite={adminData} setAdminRoleData={setRoleData} skeleton={skeleton} accessToAllSeller = {accessToAllSeller} payload = {payloadData}/>
                                    <SellerSite showSnackbar={showSnackbar} sellerSite={sellerData} setSellerRoleData={setRoleData} skeleton={skeleton} />
                                    <SellerPermission showSnackbar={showSnackbar} setPayloadData={setPayloadData} payloadData={payloadData} storeData={storeData} setAccessToAllSeller={setAccessToAllSeller} accessToAllSeller={accessToAllSeller} roleNameId={roleNameId} skeleton={skeleton} adminData={adminData}/>
                                    <ReceivingEmailPermission showSnackbar={showSnackbar} setPayloadData={setPayloadData} payloadData={payloadData} permissionData={permissionData}  skeleton={skeleton}/>
                                    {roleNameId ? (
                                        <Typography sx={{ mt: 3 }}>{t("role_management_system.confirm_save_message")}</Typography>
                                    ):null}
                                    <Box className="rms_save_btn">                                      
                                        <Button
                                            className="table_btn"
                                            variant="contained"
                                            onClick={() => handleBackBtn()}
                                        >
                                            {t("role_management_system.cancel")}
                                        </Button>                                   
                                        <Button
                                            className="table_btn"
                                            variant="contained"
                                            onClick={() => handleSaveBtn()}
                                        >
                                            {t("role_management_system.save")}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={0} sm={0} md={0} lg={0} xl={1} className="padding-0"></Grid>
                                <Grid item xs={0} sm={0} md={0} lg={4} xl={4}  className="image_upload_box_container"  >
                                    <Grid className="rms_img_box">
                                        <img className="creatUser_img" alt="cainz" src="./assets/images/EditRmsRole.png"/>
                                        <Box className="rms_rules_box">
                                            <Typography className="rms_rules_headlines"> {t("role_management_system.create_a_role_you_desire")}</Typography>
                                            <Typography>{t("role_management_system.manage_role_instruction_2")}</Typography>
                                            <Typography>{t("role_management_system.manage_role_instruction_3")}</Typography>
                                            <Typography>{t("role_management_system.manage_role_instruction_4")}</Typography>
                                            <Typography>{t("role_management_system.manage_role_instruction_5")}</Typography>
                                            <Typography>{t("role_management_system.manage_role_instruction_6")}</Typography>
                                            <Typography>{t("role_management_system.manage_role_instruction_7")}</Typography>
                                            <Typography>{t("role_management_system.manage_role_instruction_8")}</Typography>    

                                        </Box>
                                    </Grid>                        
                                </Grid>
                                <Grid item xs={0} sm={0} md={0} lg={0} xl={1}></Grid>
                            </Grid>              
                        </Container>
                    </>
                )                
            }
        </>

        
    );
};

export default CreateUserRole;