import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    Grid,
    InputAdornment,
    IconButton,
    TextField,
    CircularProgress,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PageTitle from "../../common/PageTitle";
import MUIDataTable from "mui-datatables";
import Pagination from "../../common/Pagination";
import Skeleton from "../../common/Skeleton";
import PaymentTableService from "../service/paymentTable";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SearchIcon from "@mui/icons-material/Search";
import downloadDataToExcel from "../../../utils/ExcelDownloadUtility";
import CONSTANTS, {
    LIST_LIMIT,
    SHEET_NAME,
} from "../../../constants/constants";
import { useAppSelector } from "../../../redux/hooks";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import { Crypt } from "../../../utils/Crypt";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ENC_DEC_CONFIG from "../config/config";

const PaymentTable = (props: any) => {
    const { showSnackbar, handleLoader, setPaymentTable, setPayeeInfoTableComp,
        setSelectAction,setSelectDataTable } = props; 

    const [errorForPaymentTable, setErrorForPaymentTable] = useState(-1);
    const crypt = new Crypt(setErrorForPaymentTable);
    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [limit, setLimit] = useState<any>(
        !perPageLimit ? LIST_LIMIT.PAGINATION_LIST_LIMIT : perPageLimit
    );
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [skeleton, setSkeleton] = useState(true);
    const [paymentData, setPaymentData] = useState<any>([]);
    const [decryptAndEncrypt, setdecryptAndEncrypt] = useState<Array<boolean>>([]);
    const [search, setSearch] = useState<string>("");
    const [isSearchChange, setIsSearchChange] = useState<boolean>(false);
    const [filterToggle, setFilterToggle] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dbLoading, setDbLoading] = useState<boolean>(false);
    const [isInitialValue, setisInitialValue] = useState<boolean>(true);
    const [colFilter,setColFilter] =useState<string>("");
    const [direction,setDirection]=useState<string>("");
    const [colName,setColName]=useState<string>("");
    // const [sortingIcon,setSortingIcon]=useState<any>("");

    const isUpload = useAppSelector((state) => state.common.isUpload) ?? true;
    const apiData: any[][] = [];
    const { t } = useTranslation();
    // const [, forceUpdate] = useState(0);


    useEffect(()=>{
        isUpload ? apiResponseData(currentPage, limit) : localStorageData();
    },[]);

    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearch(value);
    };

    useEffect(()=>{
        if(isUpload){
            if(colFilter||direction){
                apiResponseData(currentPage, limit);
            }       
        }     
    },[colFilter, direction]);

    const onsearchApicall = () => {
        if (isSearchChange) {
            apiResponseData(CONSTANTS.FIRST_PAGE, limit);
        }
    };
    const propItem = [onsearchApicall, search];
    lazyTypingUtils(propItem);

    useEffect(() => {
        const handlePageRefresh = () => {
            localStorage.removeItem("paymentTable");
        };
        window.addEventListener("beforeunload", handlePageRefresh);
        return () => {
            window.removeEventListener("beforeunload", handlePageRefresh);
        };
    }, []);
    

    /**
        * @param - currentPage - this will store current page value 
        * @param - limit - this will store the limit for diplaying data 
        * @returns OMS order data
        * @description - this function returns data for all orders in the systems 
   */
    const apiResponseData = (currentPage: number, limit: number) => {
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new PaymentTableService(payload, isHitDummyURL)
            .paymentList(currentPage, limit, search.trim(),colFilter,direction)
            .then((res: any) => {
                setPaymentData(res?.paymentTableLogs?.sellerPaymentData);
                handleLoader(false);
                setLimit(limit);
                setCurrentPage(currentPage);
                setTotalCount(res?.paymentTableLogs?.totalCount);
                setTotalPages(res?.paymentTableLogs?.totalPages);
                setSkeleton(false);
                const encryptArray =  new Array(paymentData).fill(false);
                setdecryptAndEncrypt(encryptArray);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                handleLoader(false);
            });
    };
    
    /**
       * @description - this function stores data to local storage 
         *for payment table and order table
    */
    const localStorageData = () => {
        const paymentDataLocal: any = localStorage.getItem("paymentTable");
        const uploadedData: any = JSON.parse(paymentDataLocal) ?? [];
        if (uploadedData.length != 0) {
            setPaymentData(uploadedData);
        }
        handleLoader(false);
        setSkeleton(false);
        const encryptArray =  new Array(paymentData).fill(true);
        if (uploadedData.length != 0) {
            uploadedData.map((item: any, index: number) => {
                const iv = `${item?.transferDate}${item?.tsConsignorCode}${item?.payeeCode}`;
                encryptArray[index] = crypt.checkDecryptWithCryptoJS(item?.transferPayeeName, iv, index, isInitialValue);
            });
        }
        setdecryptAndEncrypt(encryptArray);
    };

    /**
       * @description - this function contains functionality for normal download and db download 
   */
    const downloadPaymentData = (item:any) =>{
        item=="download"? setLoading(true):setDbLoading(true);
        isUpload ? downloadFromApi(item) : downloadFromLocal(item);
    };

    /**
     * @description - this function contains functionality for normal download and db download 
         downloadFromApi = download from DB
        downloadFromLocal = download from local. Meaning user can download file, uploaded by user themself.
    */
    const downloadFromApi = (item:any) => {
        showSnackbar(t("activity_log.download_started"), true);
        const payload = {};
        const isHitDummyURL = false;
        new PaymentTableService(payload, isHitDummyURL)
            .downloadPaymentList(search.trim(),colFilter,direction)
            .then((res: any) => {
                handleOnExport(res?.paymentTableLogs?.sellerPaymentData, item);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                item=="download"? setLoading(false):setDbLoading(false);
            });
    };

    const downloadFromLocal = (item:any) => {
        showSnackbar(t("activity_log.download_started"), true);
        const paymentDataLocal: any = localStorage.getItem("paymentTable");
        const uploadedData: any = JSON.parse(paymentDataLocal) ?? [];
        // let orderData:any;
        if (uploadedData.length != 0) {
            // orderData = uploadedData;
        }
        handleOnExport(paymentData,item);
    };

    /**
     * @param downloadArray contains array data from payment table
     * @param item to itterate array data
     * @returns all payment data 
     * @description handleOnExport function maps data as {key:value} for api and local Data download.
     */
    const handleOnExport = (downloadArray: any, item: any) => {
        const downloadData: any = []; 
        if(item=="download"){
            downloadArray?.map((item: any, index: any) => {
                const iv = `${item?.transferDate}${item?.tsConsignorCode}${item?.payeeCode}`;
                downloadData.push({
                    "id":item?.id,
                    "transfer_date": item?.transferDate,
                    "transfer_type": item?.transferType,
                    "ts_consignor_code": item?.tsConsignorCode,
                    "transfer_consignor_name": item?.transferConsignorName,
                    "tsf_institution_code": item?.tsfInstitutionCode,
                    "tsf_institution_name_kana": item?.tsfInstitutionNameKana,
                    "tsf_branch_code": item?.tsfBranchCode,
                    "tsf_branch_name_kana": item?.tsfBranchNameKana,
                    "ts_deposit_type": item?.tsDepositType,
                    "ts_account_number": item?.tsAccountNumber,
                    "payee_code": item?.payeeCode,
                    "payee_name": item?.payeeName,
                    "payee_financial_institution_code":decryptAndEncrypt[index]? decryptedValue(item?.payeeFinancialInstitutionCode, iv, index, item?.isUpload) : encryptedValue (item?.payeeFinancialInstitutionCode, iv, index, item?.isUpload),
                    "tdf_financial_institution_name_kana": decryptAndEncrypt[index]? decryptedValue( item?.tdfFinancialInstitutionNameKana, iv, index, item?.isUpload)  : encryptedValue(item?.tdfFinancialInstitutionNameKana, iv, index, item?.isUpload),
                    "payee_financial_branch_code": decryptAndEncrypt[index]? decryptedValue(item?.payeeFinancialBranchCode, iv, index, item?.isUpload)  : encryptedValue(item?.payeeFinancialBranchCode, iv, index, item?.isUpload) ,
                    "tdf_branch_name_kana":decryptAndEncrypt[index]? decryptedValue(item?.tdfBranchNameKana, iv, index, item?.isUpload) : encryptedValue(item?.tdfBranchNameKana, iv, index, item?.isUpload),
                    "transfer_destination_deposit_item": decryptAndEncrypt[index]? decryptedValue( item?.transferDestinationDepositItem, iv, index, item?.isUpload )  : encryptedValue(item?.transferDestinationDepositItem, iv, index, item?.isUpload),
                    "transfer_account_number":decryptAndEncrypt[index]? decryptedValue(item?.transferAccountNumber, iv, index, item?.isUpload)  : encryptedValue(item?.transferAccountNumber, iv, index, item?.isUpload),
                    "transfer_payee_name":decryptAndEncrypt[index]? decryptedValue(item?.transferPayeeName, iv, index, item?.isUpload)  : encryptedValue(item?.transferPayeeName, iv, index, item?.isUpload),
                    "transfer_amount":item?.transferAmount,
                    "created_at":item?.createdAt,
                    "created_by":item?.createdBy,
                    "updated_at":item?.updatedAt,
                    "updated_by":item?.updatedBy,
                    "deleted_at":item?.deletedAt,
                    "deleted_by":item?.deletedBy,
                });
            });

        }else{
            downloadArray?.map((item: any, index: any) => {
                const iv = `${item?.transferDate}${item?.tsConsignorCode}${item?.payeeCode}`;
                downloadData.push({
                    "id":item?.id,
                    "transfer_date": item?.transferDate,
                    "transfer_type": item?.transferType,
                    "ts_consignor_code": item?.tsConsignorCode,
                    "transfer_consignor_name": item?.transferConsignorName,
                    "tsf_institution_code": item?.tsfInstitutionCode,
                    "tsf_institution_name_kana": item?.tsfInstitutionNameKana,
                    "tsf_branch_code": item?.tsfBranchCode,
                    "tsf_branch_name_kana": item?.tsfBranchNameKana,
                    "ts_deposit_type": item?.tsDepositType,
                    "ts_account_number": item?.tsAccountNumber,
                    "payee_code": item?.payeeCode,
                    "payee_name": item?.payeeName,
                    "created_by":item?.createdBy,
                    "updated_by":item?.updatedBy,
                    "deleted_by":item?.deletedBy,
                    "transfer_amount": item?.transferAmount,
                    "payee_financial_institution_code":decryptAndEncrypt[index]? decryptedValue(item?.payeeFinancialInstitutionCode, iv, index, item?.isUpload) : encryptedValue(item?.payeeFinancialInstitutionCode, iv, index, item?.isUpload),
                    "payee_financial_branch_code": decryptAndEncrypt[index]? decryptedValue(item?.payeeFinancialBranchCode, iv, index, item?.isUpload)  : encryptedValue(item?.payeeFinancialBranchCode, iv, index, item?.isUpload) ,
                    "transfer_destination_deposit_item": decryptAndEncrypt[index]? decryptedValue( item?.transferDestinationDepositItem, iv, index, item?.isUpload )  :encryptedValue(item?.transferDestinationDepositItem, iv, index, item?.isUpload),
                    "transfer_account_number": decryptAndEncrypt[index]? decryptedValue(item?.transferAccountNumber, iv, index, item?.isUpload)  : encryptedValue(item?.transferAccountNumber, iv, index, item?.isUpload),
                    "created_at":item?.createdAt,
                    "updated_at":item?.updatedAt,
                    "deleted_at":item?.deletedAt,
                    "tdf_financial_institution_name_kana":decryptAndEncrypt[index]? decryptedValue( item?.tdfFinancialInstitutionNameKana, iv, index, item?.isUpload)  : encryptedValue(item?.tdfFinancialInstitutionNameKana, iv, index, item?.isUpload),
                    "tdf_branch_name_kana":decryptAndEncrypt[index]? decryptedValue(item?.tdfBranchNameKana, iv, index, item?.isUpload) : encryptedValue(item?.tdfBranchNameKana, iv, index, item?.isUpload),
                    "transfer_payee_name":decryptAndEncrypt[index]? decryptedValue(item?.transferPayeeName, iv, index, item?.isUpload)  : encryptedValue (item?.transferPayeeName, iv, index, item?.isUpload),
                }); 
            });
        }    

        const fileName =SHEET_NAME.PAYMENT_DATA;
        downloadDataToExcel(downloadData, fileName, SHEET_NAME.PAYMENT_DATA);
        item=="download"? setLoading(false):setDbLoading(false);
    };
    /**
     * @description HandleToggle function show FilterAltIcon and FilterAltOffIcon icon .
    */
    const HandleToggle = () => {
        setFilterToggle(!filterToggle);
    };

    /**
     * @description backToPrevious function reset all data .
    */
    const backToPrevious = () =>{
        setPaymentTable(false);
        setPayeeInfoTableComp(false);
        setSelectAction("");
        setSelectDataTable("");
        localStorage.removeItem("paymentTable");
        localStorage.removeItem("orderTable");
    };

    const handleSorting =(changedColumn:any,key:any)=>{
        let sortingOrder="asc";

        if(colName != changedColumn){
            setDirection("asc");

        }else{
            setDirection(direction=="asc"? "desc":"asc");
            sortingOrder = direction=="asc"? "desc":"asc";

        }
        
        setColName(changedColumn);   
        const columnName = changedColumn == "Transfer date" || changedColumn=="振込指定日" ? "transfer_date": changedColumn == "id" ? "id":changedColumn == "Payee code" || changedColumn=="振込先コード(出品者コード)" ? "payee_code":changedColumn == "Payee name" || changedColumn=="振込先名(出品者名)" ? "payee_name":changedColumn == "Transfer amount" || changedColumn == "振込金額" ? "transfer_amount":"";
        setColFilter(columnName);

        if(!isUpload){
            setSkeleton(true);
            let sortedArray=[];
            if(key == ENC_DEC_CONFIG.PAYEE_NAME || key == ENC_DEC_CONFIG.PAYEE_CODE){
                sortingOrder=="asc"?
                    sortedArray = [...paymentData].sort((a, b) => {
                        if (a[key]?.toLowerCase() != b[key]?.toLowerCase()) {
                            if(a[key]?.toLowerCase() < b[key]?.toLowerCase()){
                                return(-1);
                            }else{
                                return 1;
                            }
                        } else{
                            if(a.transferDate < b.transferDate){
                                return(-1);
                            }else{
                                return 1;
                            }

                        }
                    }):
                    sortedArray =[...paymentData].sort((a, b) => {
                        if (a[key]?.toLowerCase() != b[key]?.toLowerCase()) {
                            if(a[key]?.toLowerCase() > b[key]?.toLowerCase()){
                                return(-1);
                            }else{
                                return 1;
                            }
                        }  else{
                            if(a.transferDate > b.transferDate){
                                return(-1);
                            }else{
                                return 1;
                            }
                        }
                    });
            }else{
                sortingOrder=="asc"?
                    sortedArray = [...paymentData].sort((a, b) => {
                        if (a[key] != b[key]) {
                            if(a[key] < b[key]){
                                return(-1);
                            }else{
                                return 1;
                            }
                        } else if(a.transferDate != b.transferDate){
                            if(a.transferDate < b.transferDate){
                                return(-1);
                            }else{
                                return 1;
                            }
                        }else{
                            if(a.payeeCode < b.payeeCode){
                                return(-1);
                            }else{
                                return 1;
                            }

                        }
                    }):
                    sortedArray = [...paymentData].sort((a, b) => {
                        if (a[key] != b[key]) {
                            if(a[key] > b[key]){
                                return(-1);
                            }else{
                                return 1;
                            }
                        } else if(a.transferDate != b.transferDate){
                            if(a.transferDate > b.transferDate){
                                return(-1);
                            }else{
                                return 1;
                            }
                        }   else{
                            if(a.payeeCode > b.payeeCode){
                                return(-1);
                            }else{
                                return 1;
                            }

                        }
                    });

            }   
            setPaymentData(sortedArray);
            const encryptArray =  new Array(paymentData).fill(true);
            if (sortedArray.length != 0) {
                sortedArray.map((item: any, index: number) => {
                    const iv = `${item?.transferDate}${item?.tsConsignorCode}${item?.payeeCode}`;
                    encryptArray[index] = crypt.checkDecryptWithCryptoJS(item?.transferPayeeName, iv, index, isInitialValue);
                });
            }
            setdecryptAndEncrypt(encryptArray);
            setTimeout(()=>{
                setSkeleton(false);
    
            },100);          
        }else{
            const encryptArray =  new Array(paymentData).fill(false);
            setdecryptAndEncrypt(encryptArray);
        }
    };

    const columns = [
        {
            name:"id",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box sx={{display:"flex"}}>
                            <Typography>id</Typography>     
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name,"id")} >
                                {colFilter!="id" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton> 
                        </Box>
                    );
                }, 
            },
        },
        {
            name:t("payment_table.transfer_date"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box sx={{display:"flex"}}>
                            <Box>
                                <Typography>{t("payment_table.transfer_date")}</Typography>     
                                <Typography>(transfer_date)</Typography> 
                            </Box>                          
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name,"transferDate")} >
                                {colFilter!="transfer_date" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton> 
                        </Box>
                    );
                }, 
            },
        },
        {
            name:t("payment_table.transfer_type"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.transfer_type")}</Typography>     
                            <Typography>(transfer_type)</Typography>     
                        </Box>
                    );
                },
            },
        },

        {
            name:t("payment_table.ts_consignor_code"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.ts_consignor_code")}</Typography>     
                            <Typography>(ts_consignor_code)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.transfer_consignor_name"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.transfer_consignor_name")}</Typography>     
                            <Typography>(transfer_consignor_name)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.tsf_institution_code"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.tsf_institution_code")}</Typography>     
                            <Typography>(tsf_institution_code)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.tsf_institution_name_kana"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.tsf_institution_name_kana")}</Typography>     
                            <Typography>(tsf_institution_name_kana)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.tsf_branch_code"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.tsf_branch_code")}</Typography>     
                            <Typography>(tsf_branch_code)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.tsf_branch_name_kana"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.tsf_branch_name_kana")}</Typography>     
                            <Typography>(tsf_branch_name_kana)</Typography>     
                        </Box>
                    );
                },
            },
        },

        {
            name:t("payment_table.ts_deposit_type"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.ts_deposit_type")}</Typography>     
                            <Typography>(ts_deposit_type)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.ts_account_number"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.ts_account_number")}</Typography>     
                            <Typography>(ts_account_number)</Typography>     
                        </Box>
                    );
                },
            },
        },

        {
            name:t("payment_table.payee_code"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box sx={{display:"flex"}}>
                            <Box>
                                <Typography>{t("payment_table.payee_code")}</Typography>     
                                <Typography>(payee_code)</Typography> 
                            </Box>  
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name,"payeeCode")} >
                                {colFilter!="payee_code" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton> 
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.payee_name"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box sx={{display:"flex"}}>
                            <Box>                       
                                <Typography>{t("payment_table.payee_name")}</Typography>     
                                <Typography>(payee_name)</Typography>  
                            </Box>
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name,"payeeName")} >
                                {colFilter!="payee_name" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton> 
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.payee_financial_institution_code"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.payee_financial_institution_code")}</Typography>     
                            <Typography>(payee_financial_institution_code)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.tdf_financial_institution_name_kana"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.tdf_financial_institution_name_kana")}</Typography>     
                            <Typography>(tdf_financial_institution_name_kana)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.payee_financial_branch_code"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.payee_financial_branch_code")}</Typography>     
                            <Typography>(payee_financial_branch_code)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.tdf_branch_name_kana"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.tdf_branch_name_kana")}</Typography>     
                            <Typography>(tdf_branch_name_kana)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.transfer_destination_deposit_item"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.transfer_destination_deposit_item")}</Typography>     
                            <Typography>(transfer_destination_deposit_item)</Typography> 
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.transfer_account_number"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.transfer_account_number")}</Typography>     
                            <Typography>(transfer_account_number)</Typography> 
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.transfer_payee_name"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.transfer_payee_name")}</Typography>     
                            <Typography>(transfer_payee_name)</Typography> 
                        </Box>
                    );
                },
            },
        },
        {
            name: t("payment_table.transfer_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"}),
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box sx={{display:"flex",justifyContent: "end"}}>
                            <Box>
                                <Typography>{t("payment_table.transfer_amount")}</Typography>     
                                <Typography>(transfer_amount)</Typography> 
                            </Box>
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name,"transferAmount")} >
                                {colFilter!="transfer_amount" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name:t("payment_table.created_at"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.created_at")}</Typography>     
                            <Typography>(created_at)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("payment_table.created_by"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.created_by")}</Typography>     
                            <Typography>(created_by)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("payment_table.updated_at"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.updated_at")}</Typography>     
                            <Typography>(updated_at)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("payment_table.updated_by"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.updated_by")}</Typography>     
                            <Typography>(updated_by)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("payment_table.deleted_at"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.deleted_at")}</Typography>     
                            <Typography>(deleted_at)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("payment_table.deleted_by"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("payment_table.deleted_by")}</Typography>     
                            <Typography>(deleted_by)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("encryption_decryption.action"),
            options: {
                filter: true,
                sort: false,
            },
        },
    ];

    /**
     * @description this function use for UTF-8 error
     */
    useEffect(() => {
        if(errorForPaymentTable != -1){
            encryptFn(errorForPaymentTable);
            setErrorForPaymentTable(-1);
        }
    }, [errorForPaymentTable]);

    /**
     * @description decryptFn function use for decrypt data
     */
    
    const decryptFn = (index: any) => {
        const decryptId =  [...decryptAndEncrypt];
        decryptId[index]=true;
        setdecryptAndEncrypt(decryptId);
        setisInitialValue(false);
    };
   
    /**
     * @description encryptFn function use for encrypted data 
     */
    const encryptFn = (index: any) => {
        const encryptId =  [...decryptAndEncrypt];
        encryptId[index]=false;
        setdecryptAndEncrypt(encryptId);
        setisInitialValue(false);
    };


    const decryptedValue = (item:any, iv:any, index:number, upload:any) =>{
        if(upload){
            if(isInitialValue){
                return item;
            }
            return  crypt.checkDecryptWithCryptoJS(item, iv, index, isInitialValue) ? item : crypt.decryptWithCryptoJS(item, iv, index);
        } else {
            // Since DB contains only encrypted value so decrypt value form db
            return crypt.decryptWithCryptoJS(item, iv, index);   
        }
    };

    const encryptedValue = (item:any, iv:string, index:number, upload:any) =>{
        if(upload){
            if(isInitialValue){
                return item;
            }
            return crypt.checkDecryptWithCryptoJS(item, iv, index, isInitialValue) ? crypt.encryptWithCryptoJS(item, iv, index)  : item ;   
        }else{
            // Since DB contains only encrypted value so return encrypted value form db
            return item;       
        }
    };
    
    /**
         * @description decryptAndEncrypt is a state this return true and false on bases index 
      */
    const decryptButton = (index: any) => {
        return (
            <>
                {
                    decryptAndEncrypt[index] ? (
                        <Box className="table_btn">
                            <Button
                                key={index}
                                onClick={() => encryptFn(index)}
                                className="table_btn"
                                variant="contained"
                                color="warning"
                            >
                                {t("encryption_decryption.erncrypt")}
                            </Button>
                        </Box>
                    ) : (
                        <Box className="table_btn">
                            <Button
                                key={index}
                                onClick={() => decryptFn(index)}
                                className="table_btn"
                                variant="contained">
                                {t("encryption_decryption.decrypt")}
                            </Button>
                        </Box>
                    )
                }
            </>
        );
    };

      
    /**
      * @description tableAlignRight function use for table column show in right align
    */
    const tableAlignRight = (value: any) => {
        return (
            <Box className="text_right">{value}</Box>
        );
    };
  
    /**
     * @param paymentData contains array data from API
     * @param item to itterate array data
     * @param index to itterate array index
     * @returns all payment data 
     * @description apiTableData function contain api data and some fix item contains 
     * decryptAndEncrypt function use for decryption and Encryption.
     *  .
     */  

    const apiTableData = () => {
        {  
            paymentData?.map((item: any, index: any) => {
                const iv = `${item?.transferDate}${item?.tsConsignorCode}${item?.payeeCode}`;
                apiData[index] = [];
                apiData[index].push(
                    item?.id,
                    item?.transferDate,
                    item?.transferType,
                    item?.tsConsignorCode,
                    item?.transferConsignorName,
                    item?.tsfInstitutionCode,
                    item?.tsfInstitutionNameKana,
                    item?.tsfBranchCode,
                    item?.tsfBranchNameKana,
                    item?.tsDepositType,
                    item?.tsAccountNumber,
                    item?.payeeCode,
                    item?.payeeName,
                    // decryptAndEncrypt[index]  ? decryptedValue(item?.payeeFinancialInstitutionCode, iv, index, item?.isUpload)  : (item?.isUpload ? crypt.encryptWithCryptoJS(item?.payeeFinancialInstitutionCode, iv, index, item?.isUpload) : item?.payeeFinancialInstitutionCode),
                    decryptAndEncrypt[index]  ? decryptedValue(item?.payeeFinancialInstitutionCode, iv, index, item?.isUpload)  : encryptedValue(item?.payeeFinancialInstitutionCode, iv, index, item?.isUpload),
                    decryptAndEncrypt[index]  ? decryptedValue( item?.tdfFinancialInstitutionNameKana, iv, index, item?.isUpload)  : encryptedValue(item?.tdfFinancialInstitutionNameKana, iv, index, item?.isUpload),
                    decryptAndEncrypt[index]  ? decryptedValue( item?.payeeFinancialBranchCode, iv, index, item?.isUpload)  : encryptedValue(item?.payeeFinancialBranchCode, iv, index, item?.isUpload),
                    decryptAndEncrypt[index]  ? decryptedValue(item?.tdfBranchNameKana, iv, index, item?.isUpload) : encryptedValue(item?.tdfBranchNameKana, iv, index, item?.isUpload),
                    decryptAndEncrypt[index]  ? decryptedValue( item?.transferDestinationDepositItem, iv, index, item?.isUpload )  : encryptedValue(item?.transferDestinationDepositItem,iv, index, item?.isUpload),
                    decryptAndEncrypt[index]  ? decryptedValue(item?.transferAccountNumber, iv, index, item?.isUpload)  : encryptedValue(item?.transferAccountNumber, iv, index, item?.isUpload),
                    decryptAndEncrypt[index]  ? decryptedValue(item?.transferPayeeName, iv, index, item?.isUpload)  : encryptedValue(item?.transferPayeeName, iv, index, item?.isUpload),
                    tableAlignRight(item?.transferAmount),
                    item?.createdAt,
                    item?.createdBy,
                    item?.updatedAt,
                    item?.updatedBy,
                    item?.deletedAt,
                    item?.deletedBy,
                    decryptButton(index)
                );
            }
            );
        }
    };
    apiTableData();
    return (
        <> 
           
            <PageTitle title={t("encryption_decryption.encryption_decryption_tools")}/>

            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box className="btn_box">
                            <Box>
                                <Button variant="contained" onClick={backToPrevious}>
                                         
                                    {t("encryption_decryption.back")}
                                </Button>
                            </Box>
                            <Box>
                                {isUpload ?
                                    filterToggle ? (
                                        <FilterAltOffIcon
                                            className="filter_icon"
                                            onClick={HandleToggle}
                                        />
                                    ) : (
                                        <FilterAltIcon
                                            className="filter_icon"
                                            onClick={HandleToggle}
                                        />
                                    ):"" }
                                        
                                <Button
                                    className="margin_download_button"
                                    variant="contained"
                                    disabled={loading || paymentData.length == 0}
                                    sx={{ textTransform: "capitalize" }}
                                    onClick={()=>downloadPaymentData("download")}>
                                    {t("pr.download")}
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                marginTop: "-12px",
                                                marginLeft: "-12px",
                                            }}
                                        />
                                    )}
                                </Button>
                                <Button
                                    className="margin_download_button"
                                    variant="contained"
                                    disabled={dbLoading || paymentData.length == 0}
                                    sx={{ textTransform:"inherit", ml:1 }}
                                    onClick={()=>downloadPaymentData("dbformat")}>
                                    {t("payment_table.download_in_db_format")}
                                    {dbLoading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                marginTop: "-12px",
                                                marginLeft: "-12px",
                                            }}
                                        />
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <>
                <div className="main">
                    {skeleton ? (
                        <Skeleton
                            varient="rectangular"
                            width={0}
                            height={60}
                            count="10"
                            my={0}
                        />
                    ) : (
                        <>
                            {filterToggle && (
                                <Box className="filter_bg">
                                    <Container>
                                        <Grid container spacing={0}>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}
                                                sx={{ py: 1, px: 2 }}
                                            >
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    label={t("encryption_decryption.search_by_store_code")}
                                                    onChange={handleSearchChange}
                                                    value={search}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    <SearchIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Box>
                            )}

                            <Container sx={{ my: 4 }} className="payment_table first_table_cell_small_width">
                                <MUIDataTable
                                    title={""}
                                    columns={columns}
                                    data={apiData}
                                    options={{
                                        pagination: false,
                                        print: false,
                                        download: false,
                                        filter: false,
                                        viewColumns: false,
                                        search: false,
                                        selectableRowsHideCheckboxes: true,
                                        textLabels: {
                                            body: {
                                                noMatch: t("table_text.no_match").toString(),
                                            },
                                            viewColumns: {
                                                title: t("table_text.show_columns").toString(),
                                                titleAria: "Show/Hide Table Columns",
                                            },
                                            toolbar: {
                                                search: t("table_text.search").toString(),
                                                viewColumns: t(
                                                    "table_text.view_columns"
                                                ).toString(),
                                            },
                                        },
                                        searchPlaceholder: t(
                                            "table_text.search_placeholder"
                                        ).toString(),
                                        responsive: "standard",
                                    }}
                                />
                            </Container>
                        </>
                    )}
                </div>
            </>
            {isUpload &&
                    <Pagination
                        totalCount={totalCount} 
                        currentPage={currentPage} 
                        pageChange={apiResponseData}  
                        totalPage={totalPages} 
                        rowsPerPage={limit} 
                        rowsPerPageChange={setLimit}
                    />
            }
        </>
    );
};

export default PaymentTable;
