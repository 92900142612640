import React, { useEffect, useState, useRef } from "react";
import {
    Container,
    Grid,
    Button,
    TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../common/PageTitle";
import { useNavigate } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import AdminUserListService from "../service/AdminUserListService";
import { useParams } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import CONSTANTS from "../../../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { commonActions } from "../../../../redux/features/common/commonSlice";

const AdminUserDetails = (props: any) => {
    const { handleLoader, showSnackbar } = props;
    const dispatch = useAppDispatch();

    // useEffect(() => {
    //     adminUserDetails();
    // }, []);

    const [t] = useTranslation();
    const [name, setName] = useState<any>();
    const [email, setEmail] = useState<any>();
    const password = CONSTANTS.PASSWORD_TEXT;
    // const [password ,] = useState<any>();
    // const [isDisableTextField, setIsDisableTextfield] = useState<boolean>(false);
    // const [isShowButtons, setIsShowButtons] = useState<boolean>(false);
    const loggedInUserEmail = useAppSelector(
        (state) => state.common.loggedInUserEmail
    ) ?? "";
    const loggedInUserName = useAppSelector(
        (state) => state.common.loggedInUserName
    ) ?? "";

    const params = useParams();

    // validation
    const [, forceUpdate] = useState(0);
    
    useEffect(() => {
        forceUpdate(1);
    });

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                max: t("register.max_200_char"),
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    
    //validation

    useEffect(() => {
        params.id
            ? adminUserDetails()
            : (setName(loggedInUserName), setEmail(loggedInUserEmail));
    }, []);

    const adminUserDetails = async () => {
        const userID = params.id;
        const payload = {};
        const isHitDummyURL = false;
        new AdminUserListService(payload, isHitDummyURL)
            .adminUserDetails(parseInt(userID!))
            .then((res) => {
                const { name, email } = res;
                // loggedInUserEmail == email
                //     ? (setIsDisableTextfield(false), setIsShowButtons(true))
                //     : null;
                setName(name);
                setEmail(email);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };

    const adminNameUpdate = () => {
        if (validator.current.allValid()) {
            const payload = { name };
            const isHitDummyURL = false;
            new AdminUserListService(payload, isHitDummyURL)
                .updateAdminName()
                .then((res) => {
                    handleLoader(false);
                    setName(name);
                    dispatch(commonActions.SAVE_LOGIN_USER_NAME(name));
                    showSnackbar(res.message, true);
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        }else {
            validator.current.showMessages();
        }
    };

    const handleChange = (e: any) => {
        setName(e.target.value);
    };

    const navigate = useNavigate();
    const navigateDetailPage = () => {
        navigate(ROUTES.VERIFY_PASSWORD);
    };
    const navigateDetailPages = () => {
        navigate(ROUTES.UPDATE_EMAIL);
    };

    

    return (
        <>
            <div className="main">
                <Container>
                    <Grid container spacing={0} sx={{ py: 2 }}>
                        <PageTitle title={t("admin_user_details.personal_profile")} />
                    </Grid>

                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={2} md={2} lg={4}></Grid>

                        <Grid item xs={8} sm={6} md={4} lg={3}>
                            <TextField
                                fullWidth
                                value={name ?? ""}
                                required
                                disabled={loggedInUserEmail == email ? false : true}
                                type="text"
                                name="user_name"
                                label={t("admin_user_details.name").toString()}
                                variant="outlined"
                                onChange={(e) => handleChange(e)}
                            />
                            {validator.current.message("user_name", name, [
                                "required",
                                { max: 200 },
                            ])}
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={1}>
                            {!params.id || loggedInUserEmail == email ? (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    className="btn_custom_inline"
                                    onClick={adminNameUpdate}
                                >
                                    {t("admin_user_details.save")}
                                </Button>
                            ) : null}
                        </Grid>

                        <Grid item xs={12} sm={2} md={3} lg={4}></Grid>
                    </Grid>

                    <Grid container spacing={0} sx={{ py: 2 }}>
                        <Grid item xs={12} sm={2} md={2} lg={4}></Grid>

                        <Grid item xs={8} sm={6} md={4} lg={3}>
                            <TextField
                                fullWidth
                                disabled
                                type="text"
                                name="email"
                                label={t("admin_user_details.email").toString()}
                                variant="outlined"
                                value={email ?? ""}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={1}>
                            {!params.id || loggedInUserEmail == email ? (
                                <Button
                                    variant="contained"
                                    sx={{ textAlign: "center" }}
                                    fullWidth
                                    className="btn_custom_inline"
                                    onClick={() => navigateDetailPages()}
                                >
                                    {t("admin_user_details.update")}
                                </Button>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={2} md={3} lg={4}></Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={2} md={2} lg={4}></Grid>

                        <Grid item xs={8} sm={6} md={4} lg={3}>
                            <TextField
                                fullWidth
                                disabled
                                type="password"
                                name="password"
                                label={t("admin_user_details.password").toString()}
                                variant="outlined"
                                // onChange={(e) => handleChange(e)}
                                value={password ?? ""}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={1}>
                            {!params.id || loggedInUserEmail == email ? (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    className="btn_custom_inline"
                                    onClick={() => navigateDetailPage()}
                                >
                                    {t("admin_user_details.update")}
                                </Button>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={2} md={3} lg={4}></Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
};

export default AdminUserDetails;
