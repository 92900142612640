import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    Button,
    Box,
    Modal,
    Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import AdminUserListService from "./service/AdminUserListService";
import { useNavigate } from "react-router";
import Pagination from "../../common/Pagination";
import CONSTANTS, { LIST_LIMIT, USER_LIST_CONSTANTS } from "../../../constants/constants";
import PageTitle from "../../common/PageTitle";
import UserPagination from "../../../models/user/usersPagination";
import Skeleton from "../../common/Skeleton";
import UserModel from "../../../models/user/userModel";
import MUIDataTable from "mui-datatables";
import { ROUTES } from "../../../constants/routes";
import { useAppSelector } from "../../../redux/hooks";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import AdminUserRole from "./AdminUserRole";
import CommonButton from "../../common/Button";
import { permissionInitialValue } from "../../../redux/features/roleBasedPermission/permissionSlice";

const AdminUsers = (props: any) => {
    const { showSnackbar, handleLoader } = props;

    const [t] = useTranslation();

    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const userType = useAppSelector((state) => state.common.loggedInUserType) ?? null;

    const pathName = window.location.pathname;
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.ADMIN_LIST_LIMIT : perPageLimit );
    const [adminUserListWithPagination, setAdminUserListWithPagination] =
    useState<UserPagination>();
    const [isReload, setIsReload] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    localStorage.setItem(pathName, currentPage.toString());
    const firstPage = Number(localStorage.getItem(pathName));
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [skeleton, setSkeleton] = useState(true);
    const [search, setSearch] = useState<string>("");
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState(0);
    const [status, setStatus] = useState<any>();
    const [isPageVisited, setIsPageVisited] = useState(false);
    const [isSearchChange,setIsSearchChange]=useState<boolean>(false);
    const [openAdminUserRole,setOpenAdminUserRole] =useState<boolean>(false);
    const [roleNameId,setRoleNameId]=useState<boolean>(false);
    const [userId,setUserId]=useState<any>(null);

    const loggedInUserType = useAppSelector(
        (state) => state.permission.permissionDetails.userType
    ) ?? permissionInitialValue.permissionDetails.userType;
    const apiData: any[][] = [];

    const navigate = useNavigate();

    // useEffect(() => {
    //     if (!search) {
    //         adminUserList(firstPage, limit);
    //     }
    // }, [search]);

    useEffect(() => {
        if (isPageVisited) {
            adminUserList(firstPage, limit);
        }
    }, [isPageVisited]);

    const onsearchApicall = () =>{
        if(isSearchChange){
            adminUserList(CONSTANTS.FIRST_PAGE, limit);
        }
    };
    
    const propItem = [
        onsearchApicall,search
    ];
    lazyTypingUtils(propItem);

    // useEffect(() => {
    //     if (url != "") {
    //         const copyUrl = url;
    //         setCopyUrl(copyUrl);
    //         setInviteModalOpen(!modalOpen);
    //     }
    // }, [url]);

    const adminUserList = (currentPage: number, limit: any) => {
        setIsSearchChange(false);
        setSkeleton(true);
        const isHitDummyURL = false;
        new AdminUserListService({}, isHitDummyURL)
            .adminUserList(currentPage, search.trim(), limit)
            .then((res) => {
                setCurrentPage(currentPage);
                setLimit(limit);
                setAdminUserListWithPagination(res);
                setTotalCount(res.pagination.totalCount);
                setTotalPages(res.pagination.totalPages);
                setSkeleton(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    const handleReinviteAdmin = (email: string) => {
        handleLoader(true);
        const payload = { email: email, userType: CONSTANTS.USER_TYPE_ADMIN };
        const isHitDummyURL = false;
        new AdminUserListService(payload, isHitDummyURL)
            .reInviteAdmin()
            .then((res) => {
                handleLoader(false);
                showSnackbar(res.message, true);
                // setUrl(res.data.url);
            })
            .catch((err) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
            });
    };

    const deleteInvitation = (deleteId: number, status: number) => {
        handleLoader(true);
        const isHitDummyURL = false;
        if(status == 1) {
            new AdminUserListService({}, isHitDummyURL)
                .deleteAdminUser(deleteId)
                .then((res) => {
                    handleLoader(false);
                    setIsReload(!isReload);
                    adminUserList(currentPage, limit);
                    showSnackbar(res, true);
                    closeModal();
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                    closeModal();
                });
        } else {
            new AdminUserListService({}, isHitDummyURL)
                .deleteAdmin(deleteId)
                .then((res) => {
                    adminUserList(currentPage, limit);
                    handleLoader(false);
                    setIsReload(!isReload);
                    showSnackbar(res, true);
                    closeModal();
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                    closeModal();
                });
        }       
    };

    const onTabFocus = () => {
        setIsPageVisited(true);
    };

    const onTabBlur = () => {
        setIsPageVisited(false);
    };

    useEffect(() => {
        window.addEventListener("focus", onTabFocus);
        window.addEventListener("blur", onTabBlur);
        onTabFocus();
        return () => {
            window.removeEventListener("focus", onTabFocus);
            window.removeEventListener("blur", onTabBlur);
        };
    }, []);

    const navigateInvitePage = () => {
        localStorage.setItem("userType", CONSTANTS.USER_TYPE_ADMIN.toString());
        open(ROUTES.INVITE_PAGE, "_blank");
    };

    const navigateDetailPage = (id: number) => {
        navigate(`${ROUTES.ADMIN_USER_DETAILS}/${id}`);
    };

    // const handleKeyPress = (e: any) => {
    //     // if (e.keyCode == 13) { }
    //     adminUserList(CONSTANTS.FIRST_PAGE, limit);
       
    // };

    // const handleSearchChange = (e: any) => {
    //     setIsSearchChange(true);
    //     const { value } = e.target;
    //     setSearch(value);
    // };

    const deleteModalOpen = (deleteId: number, status: number) => {
        setModalOpen(true);
        setStatus(status);
        setDeleteUserId(deleteId);
    };

    const closeModal = () => {
        setModalOpen(false);
        // setInviteModalOpen(false);
        // setUrl("");
    };

    const actionButton = (email: string, userId: number, invitationId: number, status: number) => {
        const deleteId = invitationId;
        return (
            <>
                <Box className="table_btn">
                    <CommonButton route={window.location.pathname} functionToExecute={() => handleReinviteAdmin(email)} className="table_btn" btnName={t("admin_users_list.reinvite")} disableCondition={status == 1 ? true : false}/>
                    {/* <Button
                        className="table_btn"
                        variant="contained"
                        disabled={status == 1 ? true : false}
                        onClick={() => handleReinviteAdmin(email)}
                    >
                        {t("admin_users_list.reinvite")}
                    </Button> */}
                    <Button
                        className="table_btn"
                        style={{margin:"0px 5px"}}
                        variant="contained"
                        onClick={() => navigateDetailPage(userId)}
                        disabled={status == 1 ? false : true}
                    >
                        {t("admin_users_list.show_details")}
                    </Button>
                    <CommonButton route={window.location.pathname} functionToExecute={() => deleteModalOpen(deleteId, status)} className="table_btn" btnName={t("stock_page.delete")} disableCondition={(loggedInUserType != CONSTANTS.USER_TYPE_SUPER_ADMIN ? true : false) && (status == 1 ? true : false)}/>
                    {/* <Button
                        className="table_btn"
                        variant="contained"
                        disabled={(loggedInUserType != CONSTANTS.USER_TYPE_SUPER_ADMIN ? true : false) && (status == 1 ? true : false)}
                        onClick={() => deleteModalOpen(deleteId, status)}
                    >
                        {t("admin_users_list.delete")}
                    </Button> */}
                </Box>
            </>
        );
    };
    const handleEditAdminUserRole = (roleNameId:any,userId:any)=>{
        setOpenAdminUserRole(true);
        setRoleNameId(roleNameId);
        setUserId(userId);
    };

    const editButton = (roleNameId:any,userId:any ,status: number) => {
        return(
            <CommonButton route={window.location.pathname} functionToExecute={() => handleEditAdminUserRole(roleNameId,userId)} className="table_btn" btnName={t("admin_users_list.edit")} disableCondition={status == 1 ? false : true}/>
            // <Button
            //     className="table_btn"
            //     variant="contained"
            //     onClick={() => handleEditAdminUserRole(roleNameId)}
            // >
            //     {t("admin_users_list.edit")}
            // </Button>
        );
    };

    const apiTableData = () => {
        {
            adminUserListWithPagination?.users?.map(
                (item: UserModel, index: number) => {
                    const status = item.status == USER_LIST_CONSTANTS.STATUS_PENDING ? t("admin_users_list.inviting") :
                        item.status == USER_LIST_CONSTANTS.STATUS_EXPIRED ? t("admin_users_list.disabled") : t("admin_users_list.accepted");
                    apiData[index] = [];
                    userType == 0 ?(
                        apiData[index].push(
                            (currentPage - 1) * limit + index + 1,
                            item.name,
                            item.email,
                            status,
                            actionButton(item.email, item.userId, item.invitationId, item.status),
                            item.roleName,
                            editButton(item.roleId,item.userId, item.status)
                        )
                    ):apiData[index].push(
                        (currentPage - 1) * limit + index + 1,
                        item.name,
                        item.email,
                        status,
                        actionButton(item.email, item.userId, item.invitationId, item.status),
                    );
                }
            );
        }
    };
    apiTableData();

    const columns = [
        {
            name: t("admin_users_list.s_no"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("admin_users_list.name"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("admin_users_list.email"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("admin_users_list.status"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("admin_users_list.action"),
            options: {
                filter: true,
                sort: false,
            },
        },
        ...(userType === 0
            ? [
                {
                    name: t("admin_users_list.role_name"),
                    options: {
                        filter: true,
                        sort: false,
                    },
                },
                {
                    name: t("admin_users_list.role_setting"),
                    options: {
                        filter: true,
                        sort: false,
                    },
                },
            ]
            : []),
    ];
    

    return (
        <>
            { openAdminUserRole ? <AdminUserRole showSnackbar ={showSnackbar} selectedRoleNameId={roleNameId} userId={userId}/> : 
                (
                    <>
                        <div className="main">
                            <Container>
                                <Grid container spacing={0} sx={{ pt: 3 }}>
                                    <Grid item xs={12} sm={8} md={6} lg={6} sx={{ pt: 2 }}>
                                        <PageTitle title={t("admin_users_list.page_title")} />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={6} lg={6} sx={{ pt: 6 }}>
                                        <CommonButton route={window.location.pathname} functionToExecute={navigateInvitePage} className="store_invite_btn" btnName={t("admin_users_list.invite_user")} disableCondition={false} startIconRequired={<AddCircleIcon />}/>
                                        {/* <Button
                                            className="float_right"
                                            sx={{ py: 2 }}
                                            startIcon={<AddCircleIcon />}
                                            onClick={navigateInvitePage}
                                        >
                                            {t("admin_users_list.invite_user")}
                                        </Button> */}
                                    </Grid>
                                </Grid>
                            </Container>

                            <Container className="minimum_column  first_table_cell_small_width">
                                {/* <Grid container spacing={0} sx={{ py: 1 }}>
                        <Grid item xs={12} sm={6} md={4} lg={3} sx={{ py: 1, px: 2 }}>
                            <TextField
                                id="input-with-icon-textfield"
                                label={t("table_text.search_placeholder")}
                                onChange={handleSearchChange}
                                // onKeyUp={handleKeyPress}
                                value={search}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => adminUserList(CONSTANTS.FIRST_PAGE, limit)}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                    </Grid> */}
                                {skeleton ? (
                                    <Skeleton
                                        varient="rectangular"
                                        width={0}
                                        height={60}
                                        count="10"
                                        my={0}
                                    />
                                ) : (
                                    <>

                                        <MUIDataTable
                                            title={""}
                                            columns={columns}
                                            data={apiData}
                                            options={{
                                                pagination: false,
                                                print: false,
                                                download: false,
                                                filter: false,
                                                search: false,
                                                selectableRowsHideCheckboxes: true,
                                                textLabels: {
                                                    body: {
                                                        noMatch: t("table_text.no_match").toString(),
                                                    },
                                                    viewColumns: {
                                                        title: t("table_text.show_columns").toString(),
                                                        titleAria: "Show/Hide Table Columns",
                                                    },
                                                    toolbar: {
                                                        search: t("table_text.search").toString(),
                                                        viewColumns: t("table_text.view_columns").toString(),
                                                    },
                                                },
                                                searchPlaceholder: t(
                                                    "table_text.search_placeholder"
                                                ).toString(),
                                                responsive: "standard",
                                                onSearchChange: (searchText: any) => {
                                                    setSearch(searchText ? searchText : "");
                                                },
                                            }}
                                        />
                          
                                    </>
                                )}
                                <Pagination
                                    totalCount={totalCount}
                                    currentPage={currentPage}
                                    pageChange={adminUserList}
                                    totalPage={totalPages}
                                    rowsPerPage={limit}
                                    rowsPerPageChange={setLimit}
                                />
                            </Container>
                            <Modal
                                open={modalOpen}
                                onClose={closeModal}
                                aria-labelledby="delete-modal-title"
                                aria-describedby="delete-modal-description"
                            >
                                <Box className="center-model" sx={{ p: 4 }}>
                                    <Typography
                                        id="delete-modal-title"
                                        variant="h6"
                                        component="h2"
                                    ></Typography>
                                    <Typography id="delete-modal-description" sx={{ m: 2 }}>
                                        {t("admin_users_list.confirm_delete_message")}
                                    </Typography>
                                    <Button
                                        sx={{ m: 2, p: 1 }}
                                        onClick={() => deleteInvitation(deleteUserId, status)}
                                    >
                                        {t("admin_users_list.yes")}
                                    </Button>
                                    <Button sx={{ m: 2, p: 1 }} onClick={closeModal}>
                                        {t("admin_users_list.no")}
                                    </Button>
                                </Box>
                            </Modal>
                            {/* <Modal
                    open={inviteModalOpen}
                    onClose={closeModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="center-model">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        ></Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {copyUrl}
                        </Typography>
                        <Button
                            sx={{ mt: 2 }}
                            onClick={() => navigator.clipboard.writeText(copyUrl)}
                        >
                            {t("invitation_page.Please_copy_this_link_to_register")}
                        </Button>
                    </Box>
                </Modal> */}
                        </div>
                    </>
                )
            }          
        </>
    );
};

export default AdminUsers;
